import React, { Component } from 'react'

export default class Pricing extends Component {
    render() {
        return (
            <div id="pricing-container" class="objects-container">
                <div>
                    <h2>Personal</h2>
                    <ul>
                        <li>3 Data Tables</li>
                        <li>1 API</li>
                        <li>1,000 requests per day</li>
                        <li>Data size limit</li>
                    </ul>
                    <strong>Free</strong>
                </div>
                <div>
                    <h2>Pro</h2>
                    <ul>
                        <li>Everything in "Personal", plus:</li>
                        <li>Unlimited tables, API and requests</li>
                        <li>Custom code in APIs</li>
                        <li>Export everything (data + code) (??)</li>
                    </ul>
                    <strong>$9.99/mo</strong>
                </div>
                <div>
                    <h2>Team</h2>
                    <ul>
                        <li>Everything in "Pro", plus:</li>
                        <li>Granular access controls for team members</li>
                        <li>Multiple environments (e.g. "dev" / "prod")</li>
                        <li>API behind your own domain</li>
                        <li>Versioning</li>
                        <li>Deploy on your own cloud</li>
                        <li>GitHub integration</li>
                        <li>Daily backups of all data</li>
                        <li>Multi-factor authentication</li>
                    </ul>
                    <p><strong>$99.99/mo for 3 users</strong></p>
                    <p><strong>$29.99/mo per additional user</strong></p>
                </div>
                <div>
                    <h2>Enterprise</h2>
                    <ul>
                        <li>Everything in "Team", plus:</li>
                        <li>Data and code in a separate, dedicated VPC (??)</li>
                        <li>HIPAA compliance</li>
                        <li>SLA</li>
                        <li>Pay with invoice</li>
                    </ul>
                    <p><strong>$499.99/mo for 3 users</strong></p>
                    <p><strong>$49.99/mo per additional user</strong></p>
                </div>
            </div>
        )
    }
}
