import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { axiosInstance } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className="home-cta" onClick={() => loginWithRedirect()}>Start</button>;
};




const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className="icon" onClick={() => logout({ returnTo: window.location.origin })}>
      <FontAwesomeIcon icon={faSignOutAlt} />
    </button>
  );
};

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
    isAuthenticated && (
      <span>{user.name}</span>
    )
  );
};

const ensureUserRegistered = (auth0_user, access_token, org, inviteCode) => {
  return new Promise((resolve, reject) => {
    axiosInstance(access_token).get(`/User?email=${encodeURIComponent(auth0_user.email)}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        if (err.response && (err.response.status === 404 || err.response.status === 401)) {
          const user = {...auth0_user};
          user.org = org;
          user.invite_code = inviteCode;
          axiosInstance(access_token).post(`/User`, user)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        } else {
          reject(err);
        }
      });
  
  });
};

const UserContext = React.createContext({});

const history = createBrowserHistory();

const Auth0ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

export { LoginButton, LogoutButton, Profile, ensureUserRegistered, UserContext, history, Auth0ProtectedRoute, onRedirectCallback };
