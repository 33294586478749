import React, { Component, useContext, useEffect, useState } from 'react'
import {
  Router,
  Switch,
  Route, useLocation, Link
} from "react-router-dom";
import './App.css';
import { withAuth0 } from "@auth0/auth0-react";
import { LogoutButton, Profile, ensureUserRegistered, UserContext, history, Auth0ProtectedRoute } from './AuthControls';
import { TablesList, TableDataRoute, ApisList } from './Tables';
import { PublicHome } from './Home';
import { ApiRoute, ApiLogsRoute }  from './Apis';
import { axiosInstance } from './utils';
import Pricing from './Pricing';
import Docs from './Docs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { messageFromResponse } from './utils';

class Home extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       loading: false
    }
  }
  
  fetchList(name) {
    return axiosInstance(this.props.access_token).get(`/${name}`);
  }

  fetchData() {
    if (this.props.user && this.props.user.org && this.props.access_token && !this.state.loading) {
      this.setState({loading: true});
      Promise.all([
        this.fetchList("Table"),
        this.fetchList("Api")
      ]).then(res => {
        this.setState({
          tables: res[0].data,
          apis: res[1].data
        })
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchData();
  }

  componentDidMount() {
    this.fetchData();
  }

  onTablesUpdated(newTables) {
    this.setState({tables: newTables});
  }
  
  
  render() {
    return (
    <div id="objects-container" className="objects-container">
      <TablesList user={this.props.user} tables={this.state.tables} onTablesUpdated={this.onTablesUpdated.bind(this)} />
      <ApisList user={this.props.user} apis={this.state.apis} tables={this.state.tables} />
    </div>
  
    )
  }
}
function Settings(props) {
  const { access_token } = useContext(UserContext);
  const [org, setOrg] = useState(null);
  const [email, setEmail] = useState('');

  function onSubmit(e) {
    e.preventDefault();
    axiosInstance(access_token).post("/Invite", {email}).then(res => {
      const newOrg = {...org};
      if (!newOrg.users) {
        newOrg.users = [];
      }
      newOrg.users.push(res.data);
      setOrg(newOrg);
    }).catch(err => alert(messageFromResponse(err, "Failed to invite")));
  }

  if (!org && access_token) {
    axiosInstance(access_token).get("/Org").then(res => {
      setOrg(res.data);
    });
  }
  return <>
  <h1>Settings</h1>
  {org && <div>
    <p>{org.name}</p>
    <h2>Users</h2>
    <form onSubmit={onSubmit}>
      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <p><button type="submit">Invite</button></p>
    </form>
    <table>
      <thead>
        <tr>
        <th>Email</th>
        <th>Role</th>
        <th>Invite link</th>
        </tr>
      </thead>
      <tbody>
    {org.users.map(u => <tr key={u.email}>
      <td>{u.email}</td>
      <td>{u.role}</td>
      <td>{u.invite_code ? 
      `${window.location.protocol}//${window.location.host}/invite/${org.id}/${u.invite_code}` : ""
      }</td></tr>)}
    </tbody>
    </table>
  </div>}
  
  </>;
}
function LandFromInvite(props) {
  return <><p>Welcome to less!</p><p><Link to="/">Home</Link></p></>
}
function Routes(props) {
  let location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search + location.hash
      })
    }
  }, [location])
  return <Switch>
    <Route exact path="/">
      <Home user={props.user} access_token={props.accessToken} />
    </Route>
    <Route path="/table/:table_name">
      <TableDataRoute />
    </Route>
    <Route path="/api/:api_name/logs">
      <ApiLogsRoute />
    </Route>
    <Route path="/api/:api_name">
      <ApiRoute apis={props.apis} />
    </Route>
    <Route path="/pricing">
      <Pricing />
    </Route>
    <Route path="/docs">
      <Docs />
    </Route>
    <Route path="/settings">
      <Settings />
    </Route>
    <Auth0ProtectedRoute path="/invite/:org/:invite" component={LandFromInvite} />
  </Switch>;
}

function PublicRoutes(props) {
  let location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search + location.hash
      })
    }
  }, [location])
  return <Switch>
    <Route exact path="/">
      <PublicHome />
    </Route>
    <Route path="/pricing">
      <Pricing />
    </Route>
    <Route path="/docs">
      <Docs />
    </Route>
    <Auth0ProtectedRoute path="/invite/:invite" component={LandFromInvite} />
  </Switch>;
}


class App extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       user: {}
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth0.isLoading && !this.props.auth0.isLoading && this.props.auth0.isAuthenticated) {
      this.props.auth0.getAccessTokenSilently().then(token => {
        const { location } = window;
        let org = null, inviteCode = null;
        if (location.pathname.indexOf('/invite/') === 0) {
          const parts = location.pathname.split("/");
          org = parts[2];
          inviteCode = parts[3];
        }
        ensureUserRegistered(this.props.auth0.user, token, org, inviteCode)
        .then((user) => {
            this.setState({user, accessToken: token})
            window.$crisp.push(["set", "user:email", user.email]);
        });
      });
    }
  }
    
  
  render() {
    const { isAuthenticated, isLoading } = this.props.auth0;

    if (isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <header className="App-header">
          <a id="logo" href="/">less</a>
          <div id="header-menu">
          <a href="/docs">documentation</a>
          <button className="help icon" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            <span>help</span><FontAwesomeIcon icon={faQuestionCircle} />
          </button>
          </div>
          { isAuthenticated &&
            <span id="username">
              <Profile />
              <LogoutButton />
            </span>
          }
        </header>
        <div className="App">
    { isAuthenticated ? 
    <UserContext.Provider value={{
      user: this.state.user,
      access_token: this.state.accessToken
    }}>
      <Router history={history}>
        {<Routes user={this.state.user} accessToken={this.state.accessToken} apis={this.state.apis} />}
      </Router>
    </UserContext.Provider>
 : <Router history={history}><PublicRoutes /></Router> }
        </div>
      </div>
    );
  }
}

export default withAuth0(App);

