import React, { Component, useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import _ from 'underscore';
import AceEditor from "react-ace";
import { UserContext } from './AuthControls';
import { ApiAuthentication, authFieldMap, defaultJwtAuth, isAuthValid } from './Apis';
import { axiosInstance, messageFromResponse } from './utils';
import config from './config';

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactS3Uploader from 'react-s3-uploader';

class TableAttributes extends Component {


    onFieldPropChange(i, prop, e) {
        const new_fields = [...this.props.new_fields];
        const field = {...new_fields[i]};
        field[prop] = e.target.value;
        new_fields[i] = field;
        this.props.onChange(new_fields);
    }

    onFieldNameBlur(i, e) {
        if (i === this.props.new_fields.length - 1 && this.props.new_fields[i].name.trim() !== "") {
            const new_fields = this.props.new_fields.concat({
                name: "",
                type: "string"
            });
            this.props.onChange(new_fields);
        }
    }

    onDeleteField(i, e) {
        const new_fields = this.props.new_fields.slice(0, i).concat(this.props.new_fields.slice(i + 1))
        this.props.onChange(new_fields);
    }

    render() {
        return <ol>
        {
            this.props.new_fields.map((f, i) => 
                <li key={i}>
                    <input placeholder="Name" disabled={f.auto_generated || f.primary_key} type="text" value={f.name} onChange={this.onFieldPropChange.bind(this, i, "name")} onBlur={this.onFieldNameBlur.bind(this, i)} />
                    <select disabled={f.auto_generated || f.primary_key} value={f.type} onChange={this.onFieldPropChange.bind(this, i, "type")}>
                        <option value="string">Text</option>
                        <option value="int">Number</option>
                        <option value="bool">Boolean</option>
                    </select>
                    {this.props.edit_mode && !f.auto_generated && !f.primary_key && <button onClick={this.onDeleteField.bind(this, i)}>Delete</button>}
                </li>
            )
        }
        </ol>;
    }
}

function default_new_fields () {
    return [{name:"", type:"string"}, {name:"", type:"string"}];
}

class ExistingDbConnection extends Component {
    static contextType = UserContext;
    default_existing_table() {
        return {
            host: '',
            user: '',
            password: '',
            catalog: '',
            port: '',
        };
    }
    constructor(props) {
        super(props)
    
        this.state = {
             existing_db: this.default_existing_table(),
             tables_in_existing_db: []
        }
    }
    onExistingDbPropChange(e) {
        const db = this.state.existing_db;
        db[e.target.name] = e.target.value;
        this.setState({existing_db: db});
    }

    onTableInExistingDatabaseChange(e) {
        const tables = this.state.tables_in_existing_db.slice();
        const t = tables.find(t => t.name === e.target.name);
        t.selected = e.target.checked;
        this.setState({tables_in_existing_db: tables});
    }

    getExistingDb() {
        axiosInstance(this.context.access_token).post('/Database', {
            "database": this.state.existing_db
        }).then(res => {
            res.data.forEach(t => t.selected = false);
            this.setState({tables_in_existing_db: res.data});
        }).catch(err => {
            const message = messageFromResponse(err, "Cannot connect to database");
            alert(message);
        });
    }

    saveTablesFromExistingDb() {
        const tablesToAdd = this.state.tables_in_existing_db.filter(t => t.selected)
        if (tablesToAdd.length === 0) {
            alert("Please choose at least one table")
            return;
        }
        axiosInstance(this.context.access_token).post('/Database', {
            "database": this.state.existing_db,
            "tables": tablesToAdd.map(t => t.name),
        }).then(res => {
            this.setState({
                tables_in_existing_db: [],
                existing_db: this.default_existing_table(),
            });
            this.props.onTablesAdded(tablesToAdd);
        }).catch(err => {
            const message = messageFromResponse(err, "Cannot add table");
            alert(message);
        });
    }
    render() {
        return (
        <div>
            <form id="existing-db">
            <input type="text" name="host" placeholder="host" value={this.state.existing_db.host} onChange={this.onExistingDbPropChange.bind(this)} />
            <input type="text" name="port" placeholder="port (default: 5432)" value={this.state.existing_db.port} onChange={this.onExistingDbPropChange.bind(this)} />
            <input type="text" name="catalog" placeholder="catalog (default: postgres)" value={this.state.existing_db.catalog} onChange={this.onExistingDbPropChange.bind(this)} />
            <input type="text" name="user" placeholder="user" value={this.state.existing_db.user} onChange={this.onExistingDbPropChange.bind(this)} />
            <input type="password" name="password" placeholder="password" value={this.state.existing_db.password} onChange={this.onExistingDbPropChange.bind(this)} />
        </form>
        <button className="main-action" onClick={this.getExistingDb.bind(this)}>+ Connect</button>
        <ol>
            {this.state.tables_in_existing_db.map(t => (
                <li key={t.name}>
                    <input type="checkbox" name={t.name} onChange={this.onTableInExistingDatabaseChange.bind(this)} />
                    <label>{t.name}</label>
                </li>
            ))}
        </ol>
        {this.state.tables_in_existing_db.length > 0 && (<button onClick={this.saveTablesFromExistingDb.bind(this)}>+ Add Selected Tables</button>)}
        </div>)
    }
}

class TableIndexes extends Component {
    constructor(props) {
        super(props)
    
        const checked = {};
        props.attributes.forEach(a => {
            if (a.name.trim() !== '') {
                checked[a.name] = false;
            }
        });
        this.state = {
            checked,
            indexes: [],
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const checked = {...this.state.checked};
        let changed = false;
        this.props.attributes.forEach(a => {
            if (a.name.trim() !== '' && !(a.name in checked)) {
                checked[a.name] = false;
                changed = true;
            }
        });
        if (changed) {
            this.setState({checked});
        }
    }
    
    check(e) {
        const checked = {...this.state.checked};
        checked[e.target.name] = !checked[e.target.name];
        this.setState({checked});
    }
    add(e) {
        const indexes = [...this.state.indexes];
        const checked = {...this.state.checked};
        const columns = [];
        this.props.attributes.forEach(a => {
            if (checked[a.name]) {
                columns.push(a.name);
            }
        })
        if (columns.length === 0) {
            alert('Please select at least one attribute');
            return;
        }
        for (let a in checked) {
            checked[a] = false;
        }
        indexes.push(columns);
        this.props.onChange(indexes);
        this.setState({indexes, checked});
    }
    delete(i, e) {
        const indexes = [...this.state.indexes];
        indexes.splice(i, 1);
        this.props.onChange(indexes);
        this.setState({indexes});
    }

    render() {
        return <div>
            <ul>
                {this.props.attributes.filter(a => a.name.trim() !== '').map(a => <li key={a.name}>
                    <input name={a.name} checked={a.name in this.state.checked ? this.state.checked[a.name] : false} type="checkbox" onChange={this.check.bind(this)} /><label>{a.name}</label>
                </li>)}
            </ul>
            <button onClick={this.add.bind(this)}>Add index</button>
            <ul>
                {this.state.indexes.map((ind, i) => <li key={i}>
                    {ind.join(", ")}<button className="link-button" onClick={this.delete.bind(this, i)}>Delete</button>
                </li>)}
            </ul>
        </div>
    }
}


export class TablesList extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)
    
        this.state = {
             loaded: false,
             new_table: '',
             new_fields: default_new_fields(),
             table_type: '',
             existing_db_expanded: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tables && !this.state.tables) {
            this.setState({
                tables: this.props.tables,
                loaded: true
            });
        }
    }
    
    addTable() {
        if (!this.state.new_table) {
            alert("Please enter a table name");
            return;
        }
        if (this.state.tables.some(t => t.name === this.state.new_table)) {
            alert("This table already exists");
            return;
        }
        if (this.state.table_type === '') {
            alert("Please choose table type");
            return;
        }
        if (this.state.new_fields.every(f => f.name.trim() === "")) {
            alert("Please add at least one attribute");
            return;
        }
        axiosInstance(this.context.access_token).post('/Table', {
            name: this.state.new_table,
            attributes: this.state.new_fields.filter(f => f.name.trim() !== ""),
            type: this.state.table_type,
            indexes: this.state.new_indexes
        }).then((res) => {
            const newTables = this.state.tables.concat(res.data);
            this.setState(state => {
                state.tables = newTables;
                state.new_table = "";
                state.new_fields = default_new_fields();
                state.table_type = '';
                state.new_indexes = [];
                return state;
            })
            if (typeof this.props.onTablesUpdated === "function") {
                this.props.onTablesUpdated(newTables);
            }
        }).catch(err => {
            console.log(err);
            alert(messageFromResponse(err, "Cannot create table"))
        });
    }

    deleteTable(table_name, e) {
        const table = this.state.tables.find(t => t.name === table_name);
        if (!table) {
            return;
        }
        let msg = table.external === true ? "APIs dependent on this table will break." : "All data will be lost.";
        msg += " Are you sure?";
        if (window.confirm(msg)) {
            axiosInstance(this.context.access_token).delete(`/Table/?name=${table_name}`)
                .then(res => {
                    const tables = this.state.tables.filter(t => t.name !== table_name);
                    this.setState({ tables });
                    if (typeof this.props.onTablesUpdated === "function") {
                        this.props.onTablesUpdated(tables);
                    }
                })
        }
    }

    onTableNameChange(e) {
        this.setState({new_table: e.target.value});
    }

    onTableTypeChange(e) {
        this.setState({table_type: e.target.value});
    }

    onAttributeChange(new_fields) {
        this.setState({new_fields});
    }

    onTablesAdded(addedTables) {
        this.setState({tables: this.state.tables.concat(addedTables)});
    }

    indexesChanged(new_indexes) {
        this.setState({new_indexes});
    }
    
    render() {
        if (!this.state.loaded) {
            return (<div>Loading...</div>);
        }
        return (
            <div>
                <h2>Tables</h2>
                <div className="new-object">
                <h3>Create a new table (hosted by less)</h3>
                <input type="text" placeholder="Name" value={this.state.new_table} onChange={this.onTableNameChange.bind(this)} />
                <select value={this.state.table_type} onChange={this.onTableTypeChange.bind(this)}>
                    <option value="">Table type:</option>
                    <option value="dynamodb">DynamoDB</option>
                    <option value="postgres">PostgreSQL</option>
                </select>
                <h4>Attributes:</h4>
                <TableAttributes onChange={this.onAttributeChange.bind(this)} new_fields={this.state.new_fields} />

                {this.state.new_fields.filter(f => f.name.trim() !== '').length > 0 && 
                <div>
                    <h4>Indexes:</h4>
                    <TableIndexes attributes={this.state.new_fields} onChange={this.indexesChanged.bind(this)} />
                </div>}

                <button className="main-action" onClick={this.addTable.bind(this)}>+ Create Table</button>
                </div>
                <h3>Or: <span className="link-button" onClick={() => {this.setState({existing_db_expanded: !this.state.existing_db_expanded})}}>connect to an existing PostgreSQL database</span></h3>
                <div style={{display: this.state.existing_db_expanded ? "block" : "none"}}>
                    <ExistingDbConnection onTablesAdded={this.onTablesAdded.bind(this)} />
                </div>
                <hr />
                { this.state.tables.length === 0 ? <p>No tables yet - please create one.</p> :
                <div className="existing"><h3>Existing tables</h3>
                <table className="existing-objects">
                    <tbody>
                    {this.state.tables.map(t => 
                        <tr key={t.name}>
                            <td><Link to={`/table/${t.name}`}>{t.name}</Link></td>
                            <td><button className="link-button" onClick={this.deleteTable.bind(this, t.name)}>{t.external ? "Forget": "Delete"}</button></td>
                        </tr>
                    )}
                </tbody></table></div>}
            </div>
        )
    }
}

export class ApiCustomCodeEditor extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             include_custom_code: this.props.include_custom_code
        }
    }
    
    render() {
        return (
        <div id="custom-code-container">
            <input id="include_custom_code" type="checkbox" checked={this.props.include_custom_code} name="include_custom_code" onChange={this.props.onIncludeCustomCodeChange} />
            <label htmlFor="include_custom_code">Include custom code:</label>
            <a target="_blank" href="/docs"><FontAwesomeIcon icon={faQuestionCircle} /></a>
            <AceEditor
                name="new-api-editor"
                mode="python"
                theme="github"
                height={this.props.include_custom_code ? "400px" : "80px"}
                style={{
                    width: `100%`,
                    padding: `0`,
                    margin: `0`,
                }}
                readOnly={!this.props.include_custom_code}
                value={this.props.custom_code}
                onChange={this.props.onEditorChange} />
        </div>);
    }
}

export const defaultCustomCode = () => {
    return `def before_get(table_config, orm, params, access_token):
    return True


def after_get(table_config, orm, result_set):
    return True


def before_insert(table_config, orm, values, access_token):
    return True


def after_insert(table_config, orm, values):
    return True


def before_delete(table_config, orm, params, access_token):
    return True


def after_delete(table_config, orm, params):
    return True


def before_update(table_config, orm, key, update_values, access_token):
    return True


def after_update(table_config, orm, key, update_values):
    return True


def custom_get(path, table_configs, orm, params):
    return False


def custom_post(path, table_configs, orm, params, values):
    return False


def custom_delete(path, table_configs, orm, params):
    return False`;
}


export class ApisList extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props)
    
        this.state = {
             loaded: false,
             new_table: '',
             selected_tables: [],
             include_custom_code: false,
             custom_code: defaultCustomCode(),
             interval: -1,
             jwt_auth: defaultJwtAuth()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.apis && !this.state.apis) {
            this.setState({
                apis: this.props.apis,
                tables: this.props.tables,
                loaded: true
            });
        }
    }

    componentDidMount() {
        this.pollNewApiStackStatus();
    }

    componentWillUnmount() {
        window.clearInterval(this.state.interval);
    }
    
    

    pollNewApiStackStatus() {
        const max_polls = 30;
        let current_poll = 0;
        const interval = window.setInterval(() => {
            if (current_poll >= max_polls) {
                window.clearInterval(interval);
                return;
            }
            if (!this.state.apis || this.state.apis.every(a => !('status' in a) || a.status !== "Pending")) {
                return;
            }
            current_poll++;
            axiosInstance(this.context.access_token).get(`/Api/`)
            .then(res => {
                this.setState({
                    apis: res.data
                });
            }).catch(err => {
                console.log('Failed to retrieve pending APIs status');
            });
        }, 15000);
        this.setState({interval});
    }

    addTable() {
        if (!this.state.new_table) {
            alert("Please enter an API name");
            return;
        }
        if (this.state.apis.some(t => t.name === this.state.new_table)) {
            alert("This API already exists");
            return;
        }
        if (this.state.selected_tables.length === 0) {
            alert("Please select at least one table");
            return;
        }
        if (!isAuthValid(this.state.jwt_auth)) {
            return;
        }
        const body = {
            name: this.state.new_table,
            tables: this.state.selected_tables.map(t => { return { name: t }; })
        };
        if (this.state.jwt_auth.enabled) {
            for (let f in authFieldMap) {
                body[authFieldMap[f].database_field] = this.state.jwt_auth[f];
            }
        } else {
            body.jwt_authentication = false;
        }

        if (this.state.include_custom_code) {
            body.custom_code = this.state.custom_code;
        }
        axiosInstance(this.context.access_token).post('/Api', body).then((res) => {
            this.setState(state => {
                state.apis = state.apis.concat(res.data);
                state.new_table = "";
                state.selected_tables = [];
                state.custom_code = defaultCustomCode();
                state.include_custom_code = false;
                return state;
            })
        }).catch(err => {
            const message = messageFromResponse(err, 'Unable to create API');
            alert(message)
        });
    }

    deleteTable(table_name, e) {
        if (window.confirm("The API will be deleted permanently. Are you sure?")) {
            axiosInstance(this.context.access_token).delete(`/Api/?name=${table_name}`)
                .then(res => {
                    const apis = this.state.apis.filter(t => t.name !== table_name);
                    this.setState({ apis });
                })
        }
    }

    onTableNameChange(e) {
        this.setState({new_table: e.target.value})
    }

    onFieldPropChange(e) {
        const selected_tables = e.target.checked ? 
            this.state.selected_tables.concat([e.target.name]) :
            this.state.selected_tables.filter(t => t !== e.target.name);
        this.setState({selected_tables});
    }

    onIncludeCustomCodeChange(e) {
        this.setState({include_custom_code: e.target.checked});
    }

    onEditorChange(val, e) {
        this.setState({custom_code: val});
    }

    onJwtAuthChange(jwt_auth) {
        this.setState({jwt_auth});
    }

    render() {
        if (!this.state.loaded) {
            return (<div>Loading...</div>);
        }
        return (
            <div>
                <h2>APIs</h2>
                <div className="new-object">
                <h3>Create a new API</h3>
                <input type="text" placeholder="Name" value={this.state.new_table} onChange={this.onTableNameChange.bind(this)} />
                <h4>This API can access the following tables:</h4>
                {
                    this.props.tables.length > 0 ?
                    <ol>
                    {
                        this.props.tables.map((t, i) => <li key={i}>
                            <input type="checkbox" name={t.name} checked={this.state.selected_tables.some(name => name === t.name)} onChange={this.onFieldPropChange.bind(this)} />
                            <label>{t.name}</label>
                        </li>)
                    }
                </ol> : <p>No tables yet - please create one.</p>
                }
                <h4>Authentication</h4>
                <ApiAuthentication onChange={this.onJwtAuthChange.bind(this)} />
                <ApiCustomCodeEditor
                    include_custom_code={this.state.include_custom_code}
                    onIncludeCustomCodeChange={this.onIncludeCustomCodeChange.bind(this)}
                    custom_code={this.state.custom_code}
                    onEditorChange={this.onEditorChange.bind(this)}
                />
                <button className="main-action" onClick={this.addTable.bind(this)}>+ Create API</button>
                </div>
                <hr />
                {this.state.apis.length === 0 ? <p>No APIs yet - please create one.</p>:
                <div className="existing"><h3>Existing APIs</h3>
                <table className="existing-objects"><tbody>
                    {this.state.apis.map(t => 
                        <tr key={t.name}>
                            <td><Link to={`/api/${t.name}`}>{t.name}</Link></td><td>{t.status}{t.status === "Pending" && <FontAwesomeIcon icon={faCircleNotch} spin /> }</td>
                            <td><button className="link-button" onClick={this.deleteTable.bind(this, t.name)}>Delete</button></td>
                        </tr>
                    )}
                </tbody></table></div>}
            </div>
        )
    }
}

class TableForm extends Component {
    static contextType = UserContext;
    defaultRecordState() {
        const record = {};
        this.props.table_config.attributes.forEach(a => {
            record[a.name] = a.type === 'bool' ? false : '';
        });
        return record;
    }
    constructor(props) {
        super(props)
    
        this.state = {
             new_record: this.defaultRecordState()
        }
    }
    
    onSubmitNew (e) {
        e.preventDefault();
        const { table_config } = this.props;
        axiosInstance(this.context.access_token).post(`/UserTable/${table_config.name}/`, this.state.new_record)
            .then(res => {
                this.props.on_record_added(res.data);
                this.setState({ 
                    new_record: this.defaultRecordState()
                });
            })
            .catch(err => alert(messageFromResponse(err), "Cannot add record"));
    }

    onFormFieldChange (e) {
        const { target } = e;
        this.setState(state => {
            const new_state = {...state};
            if (target.type === 'checkbox') {
                new_state.new_record[target.name] = target.checked;
            } else {
                new_state.new_record[target.name] = target.value;
            }
            return new_state;
        })
    }
    render () {
        return (
            <form onSubmit={this.onSubmitNew.bind(this)}>
                {this.props.table_config.attributes.filter(a => !a.auto_generated).map(a => {
                    let control;
                    let label;
                    switch(a.type) {
                        case "bool":
                            label = <label>{a.name}</label>;
                            control = <input type="checkbox" placeholder={a.name} checked={this.state.new_record[a.name]} name={a.name} onChange={this.onFormFieldChange.bind(this)} />;
                            break;
                        case "string":
                        case "int":
                        default:
                            control = <input type="text" placeholder={a.name} value={this.state.new_record[a.name]} name={a.name} onChange={this.onFormFieldChange.bind(this)} />;
                    }
                    return <div key={a.name}>
                        {label}
                        {control}
                    </div>;
                })}
                <button type="submit">Add</button>
            </form>
        );
    }
}
function DataTablePaging(props) {
    let [currentPage, setCurrentPage] = useState(0);
    function prevClicked() {
        currentPage--;
        setCurrentPage(currentPage);
        props.onChange(currentPage);
    }
    function nextClicked() {
        currentPage++;
        setCurrentPage(currentPage);
        props.onChange(currentPage);
    }
    return <div className="data-table-paging">
        <span>Paging: </span>
        <button onClick={prevClicked} disabled={props.disabled}>&lt;</button>
        <span>Displaying records</span> <strong>{currentPage * props.pageSize + 1} - {(currentPage + 1) * props.pageSize}</strong>
        <button onClick={nextClicked} disabled={props.disabled}>&gt;</button>
        {props.disabled && <span>Loading...</span>}
    </div>
}
function DataTableFilter(props) {
    const [appliedIndex, setAppliedIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [indexValues, setIndexValues] = useState({});
    function onFilterSelect(e) {
        const index = props.indexes.find(ind => ind.name === e.target.value);
        setSelectedIndex(index);
        const emptyValues = {};
        index.columns.forEach(c => emptyValues[c.name] = "");
        setIndexValues(emptyValues);
    }
    function onIndexValueChange(e) {
        const {name, value} = e.target;
        const newIndexValues = {...indexValues};
        newIndexValues[name] = value;
        setIndexValues(newIndexValues);
    }
    function indexApplied(e) {
        e.preventDefault();
        props.onFilter({index: selectedIndex, values: indexValues});
        setAppliedIndex({
            index: selectedIndex, 
            values: indexValues
        });
        setSelectedIndex(null);
    }
    function removeIndex() {
        props.onFilter(null);
        setAppliedIndex(null);
    }
    return <div>
        <label>Filter</label>
        <select onChange={onFilterSelect}>
            <option value="">Choose:</option>
            {props.indexes.map((ind, i) => <option selected={selectedIndex && selectedIndex.name === ind.name} key={i} value={ind.name}>
                {ind.columns.map(c => c.name).join(", ")}
            </option>)}
        </select>
        {appliedIndex && <div>
            {appliedIndex.index.columns.map(c => `${c.name}: ${appliedIndex.values[c.name]}`).join(", ")}
            <button onClick={removeIndex}>Remove</button>
            </div>}
        {selectedIndex && <form onSubmit={indexApplied}>{
            selectedIndex.columns.map((c, i) =>
            <input key={i} type="text" placeholder={c.name} name={c.name} value={indexValues[c.name]} onChange={onIndexValueChange} />
            )
        }
        <button type="submit">Apply</button>
        </form>}
    </div>
}
class DataTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             edit_record: null,
             edited_values: {}
        }
    }

    onRecordChange(e) {
        const record = {...this.state.edited_values};
        record[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        this.setState({edited_values: record});
    }

    switchToEditMode(id) {
        const record = this.props.data.find(r => r.id === id);
        if (record) {
            this.setState({
                edited_values: {...record},
                edit_record: id
            });
        }
    }

    updateRecord() {
        this.props.onUpdateRecord(this.state.edited_values)
        .then(res => this.setState({edit_record: null}));
    }
    render() {
        const  { table_config, data, showPaging, onFilterApplied } = this.props;
        if (data === null) {
            return <p>Loading...</p>
        }
        const attributes_by_name = _.indexBy(table_config.attributes, 'name');
        const display_data = data.map(d => {
            const translated = {};
            for (let f in d) {
                if (f in attributes_by_name && attributes_by_name[f].type === 'bool') {
                    translated[f] = d[f] === true ? 'Yes' : 'No';
                } else {
                    translated[f] = d[f];
                }
            }
            return translated;
        });
        return (
            <div>
            {table_config.indexes && <DataTableFilter indexes={table_config.indexes} onFilter={(filter) => onFilterApplied && onFilterApplied(filter)} />}
            {showPaging && <DataTablePaging disabled={this.props.pagingDisabled} pageSize="100" onChange={this.props.onPageChange} />}
            {!showPaging && data.length >= 100 && <span>Showing first 100 records only</span>}
            <table className="table-records">
                <thead>
                    <tr>
                        {table_config.attributes.map(a => <th key={`th_${a.name}`}>{a.name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {display_data.map(r => 
                        <tr key={r.id}>
                            {table_config.attributes.map(a => 
                                <td key={`th_${r.id}_${a.name}`}>
                                    {this.state.edit_record === r.id && !a.auto_generated ? 
                                    (a.type === 'bool' ? 
                                <input type="checkbox" name={a.name} checked={this.state.edited_values[a.name]} onChange={this.onRecordChange.bind(this)} /> : 
                                <textarea name={a.name} value={this.state.edited_values[a.name]} onChange={this.onRecordChange.bind(this)} />
                                    ) : 
                                    <div>{r[a.name]}</div>}
                                </td>
                            )}
                            <td key={`edit_${r.id}`}>
                                {
                                    this.state.edit_record === r.id ?
                                    <div>
                                        <button onClick={() => this.setState({edit_record: null})}>Cancel</button>
                                        <button onClick={this.updateRecord.bind(this)}>Save</button>
                                    </div> :
                                    <button onClick={this.switchToEditMode.bind(this, r.id)}>Edit</button>
                                }
                            </td>
                            <td key={`delete_${r.id}`}><button onClick={() => this.props.onDeleteRecord(r.id)}>Delete</button></td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
        )
    }
}

function ColumnSelect(props) {
    return <select onChange={(e) => props.onChange(e, props.index)}>
        <option value={-1} key={-1}>Choose column:</option>
        {props.columns.map((c, i) => <option value={c} key={i}>{c}</option>)}
    </select>;
}
function TableImportMapping(props) {
    const [mapping, setMap] = useState([{source: -1, target: -1}]);
    function modifyMap(newMapping) {
        setMap(newMapping);
        if (typeof props.onChange === "function") {
            props.onChange(newMapping);
        }
    }
    function sourceOnChange(e, i) {
        const newMapping = [...mapping];
        let sourceIndex = 0;
        if (isNaN(e.target.value)) {
            for (let j = 0; j < props.sourceColumns.length; j++) {
                if (props.sourceColumns[j] === e.target.value) {
                    sourceIndex = j;
                    break;
                }
            }
        } else {
            sourceIndex = parseInt(e.target.value);
        }
        newMapping[i].source = sourceIndex;
        if (e.target.value !== -1 && mapping[mapping.length - 1].source !== -1) {
            newMapping.push({source: -1, target: -1});
        }
        modifyMap(newMapping);
    }
    function targetOnChange(e, i) {
        const newMapping = [...mapping];
        newMapping[i].target = e.target.value;
        modifyMap(newMapping);
    }
    return <ul>{mapping.map((m, i) => <li key={i}>
        <ColumnSelect index={i} columns={props.sourceColumns} onChange={sourceOnChange} />
        <span>=&gt;</span>
        <ColumnSelect index={i} columns={props.targetColumns} onChange={targetOnChange} />
    </li>)}</ul>;
}
function TableImport(props) {
    const [key, setKey] = useState(null);
    const [data, setData] = useState(null);
    const [sourceColumns, setSourceColumns] = useState([]);
    let [columnMapping, setColumnMapping] = useState(null);
    const [hasHeader, setHasHeader] = useState(false);
    const [countString, setCountString] = useState("");
    const [processingStatus, setProcessingStatus] = useState("");

    function updateSourceColumns(fileData, hasHeader) {
        const columns = [];
        for (let i = 0; i < fileData[0].length; i++) {
            columns.push(hasHeader ? fileData[0][i] : i);
        }
        setSourceColumns(columns);
    }
    function getData(key) {
        axiosInstance(props.access_token).get(`ImportUpload?key=${encodeURIComponent(key)}`)
        .then(res => {
            setData(res.data.records);
            setCountString(res.data.more_records ?
                `More than ${res.data.count} records; only first ${res.data.count} will be imported` :
                `${res.data.count} records`)
            updateSourceColumns(res.data.records, hasHeader);
        });
    }
    function onImport() {
        if (columnMapping) {
            columnMapping = columnMapping.filter(m => m.source !== -1 && m.target !== -1);
        }
        if (!columnMapping) {
            alert("Please map at least one column");
            return;
        }
        const mappedTargets = {};
        for (let i = 0; i < columnMapping.length; i++) {
            if (columnMapping[i].target in mappedTargets) {
                alert(`Invalid mapping: more than one column mapped to "${columnMapping[i].target}"`);
                return;
            }
            mappedTargets[columnMapping[i].target] = true;
        }
        setProcessingStatus("Importing");
        const url = `ImportUpload?key=${encodeURIComponent(key)}&table_name=${encodeURIComponent(props.table_config.name)}`;
        axiosInstance(props.access_token).post(url, {columnMapping, hasHeader})
        .then(res => {
            setData(null);
            setSourceColumns([]);
            setColumnMapping(null);
            setProcessingStatus("");
            if (typeof props.afterImport === "function") {
                props.afterImport();
            }
        });
    }
    function hasHeaderOnChange(e) {
        const hasHeader = e.target.checked;
        setHasHeader(hasHeader);
        updateSourceColumns(data, hasHeader);
    }
    return <div id="csv-importer">
    <ReactS3Uploader
        signingUrl={`${config.api_root}ImportUploadUrl`}
        signingUrlMethod="GET"
        accept="text/csv"
        onSignedUrl={(res) => {
            setKey(res.key);
            setProcessingStatus("Uploading");
        }}
        onError={(message, file, context) => {
            setData(null);
            setCountString("");
            setProcessingStatus("");
            const msg = context.response ? JSON.parse(context.response).message : "Error uploading file";
            alert(msg);
        }}
        onFinish={(res) => {
            setProcessingStatus("");
            getData(res.key);
        }}
        signingUrlHeaders={{ Authorization: `Bearer ${props.access_token}` }}
        uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
        contentDisposition="auto"
        autoUpload={true}
    />
    {processingStatus && <span>{processingStatus}...</span>}
    {data && <>
        <table><tbody>
            {data.map((row, i) => <tr key={i}>{
                row.map((cell, i) => <td key={i}>{cell}</td>)
            }</tr>)}
        </tbody></table>
        <input type="checkbox" checked={hasHeader} onChange={hasHeaderOnChange} />
        <label>This file has a header</label>
        <h3>Column mapping:</h3>
        <TableImportMapping
        sourceColumns={sourceColumns}
        targetColumns={props.table_config.attributes.map(a => a.name)}
        onChange={(newMap) => setColumnMapping(newMap)}
        />
        <p>{countString}</p>
        <button type="button" onClick={onImport}>Import</button>
    </>}
    </div>
}

class TableData extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            loaded: false,
            table_config: {},
            records: null,
            unmounted: false,
            edit_mode: false,
            edit_mode_attributes: [],
            name_edit_mode: false,
            new_name: '',
            offset: 0,
            serverQueryOffset: 0,
            filter: {index: null, values: null}
        }
    }

    componentDidMount() {
        const { user } = this.state;
        if (!this.state.loading && !this.state.loaded && user && user.org) {
            this.fetchConfigAndData();
        }
    }

    default_edit_mode_attributes(current_attributes) {
        const attributes = current_attributes.concat(default_new_fields());
        attributes.forEach(a => {
            a.original_name = a.name;
            a.original_type = a.type;
        })
        return attributes;
    }

    setRecordsStateAfterFetch(newRecords, queryOffset, filter) {
        const newState = { offset: queryOffset, serverQueryOffset: queryOffset, loading: false, filter };
        if (this.state.table_config && this.state.table_config.is_paging_supported && 
            this.state.records && queryOffset >= this.state.records.length) {
            newState.records = this.state.records.concat(newRecords);
        } else {
            newState.records = newRecords;
        }
        this.setState(newState);
    }
    fetchTableData(newQueryOffset, filter) {
        this.setState({loading: true});
        if (typeof newQueryOffset === "undefined") {
            newQueryOffset = this.state.serverQueryOffset;
        }
        if (typeof filter === "undefined") {
            filter = this.state.filter;
        }
        let url = `/UserTable/${this.props.table_name}/`;
        let qs = [];
        if (this.state.table_config.is_paging_supported) {
            qs.push(`_offset=${newQueryOffset}`);
        }
        if (filter && filter.index && filter.values) {
            filter.index.columns.forEach(c => qs.push(`${c.name}=${encodeURIComponent(filter.values[c.name])}`))
        }
        if (qs) {
            url += `?${qs.join("&")}`;
        }
        axiosInstance(this.props.access_token).get(url)
        .then(res => this.setRecordsStateAfterFetch(res.data, newQueryOffset, filter))
        .catch(err => {
            if (this.state.table_config.type === "dynamodb") {
                window.setTimeout(() => {
                    // second try if this is a brand new DynamoDB table and wasn't created yet
                    axiosInstance(this.props.access_token).get(url)
                    .then(res => this.setRecordsStateAfterFetch(res.data), newQueryOffset, filter)
                    .catch(err => alert(messageFromResponse(err), "Cannot read table records"))
                }, 3000);
            } else {
                alert(messageFromResponse(err), "Cannot read table records");
            }
        });
    }
    fetchConfigAndData() {
        this.setState({ loading: true });
        axiosInstance(this.props.access_token).get(`/Table/?name=${this.props.table_name}`)
            .then(res => {
                this.setState({
                    table_config: res.data,
                    edit_mode_attributes: this.default_edit_mode_attributes(res.data.attributes),
                    loading: false,
                    loaded: true,
                    new_name: res.data.name
                });
                this.fetchTableData();
            }).catch(err => alert(messageFromResponse(err), "Cannot read table configuration"));
    }

    componentDidUpdate(prevProps, prevState) {
        const { user } = this.state;
        if (!this.state.loading && !this.state.loaded && user && user.org) {
            this.fetchConfigAndData();
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            user: props.user
        };
    }

    onRecordAdded(new_record) {
        this.setState(state => {
            state.records = state.records.concat([new_record]);
            return state;
        });
    }

    onAttributeChange(new_fields) {
        this.setState({edit_mode_attributes: new_fields});
    }

    onChangeEditMode() {
        const edit_mode = !this.state.edit_mode;
        this.setState({edit_mode});
    }

    cancelAttributeChange() {
        this.setState({edit_mode_attributes: this.default_edit_mode_attributes(this.state.table_config.attributes), edit_mode: false});
    }

    submitAttributeChange() {
        for (let i = 0; i < this.state.edit_mode_attributes.length; i++) {
            const a = this.state.edit_mode_attributes[i];
            if (a.name.trim() === '' && 'original_name' in a && a.original_name !== '') {
                alert('Cannot set name to empty string')
                return;
            }
        }
        const new_attributes = this.state.edit_mode_attributes.filter(a => a.name.trim() !== '')
        axiosInstance(this.props.access_token).post('/Table', {
            attributes: new_attributes,
            name: this.state.table_config.name
        })
            .then(res => {
                const table_config = {...this.state.table_config};
                table_config.attributes = new_attributes;
                this.setState({
                    edit_mode: false,
                    edit_mode_attributes: this.default_edit_mode_attributes(new_attributes),
                    table_config: table_config
                });
            }).catch(err => alert(messageFromResponse(err, "Cannot update attributes")));
    }

    onNameChange(e) {
        this.setState({new_name: e.target.value});
    }

    submitNameChange() {
        axiosInstance(this.props.access_token).post('/Table', {
            new_name: this.state.new_name,
            name: this.state.table_config.name
        })
            .then(res => {
                const table_config = {...this.state.table_config};
                table_config.name = this.state.new_name;
                this.setState({
                    table_config: table_config,
                    name_edit_mode: false
                });
            }).catch(err => alert(messageFromResponse(err, "Cannot rename")));
    }

    deleteRecord(id) {
        axiosInstance(this.props.access_token).delete(`/UserTable/${this.state.table_config.name}?id=${id}`)
        .then(res => {
            const records = this.state.records.filter(r => r.id !== id);
            this.setState({records});
        }).catch(err => alert(messageFromResponse(err, "Failed to delete")));
    }

    updateRecord(record) {
        return new Promise((resolve, reject) => {
            axiosInstance(this.props.access_token).post(`/UserTable/${this.state.table_config.name}`, record)
            .then(res => {
                const records = [...this.state.records];
                const updated_record = records.find(r => r.id === record.id);
                for (let k in record) {
                    updated_record[k] = record[k];
                };
                this.setState({records});
                resolve(res);
            }).catch(err => {
                alert(messageFromResponse(err, "Cannot update record"));
                reject(err);
            });
        });
    }
    onPageChange(newPage) {
        if ((newPage) * 100 < this.state.records.length) {
            this.setState({
                offset: (newPage) * 100
            })
        } else {
            const newQueryOffset = this.state.serverQueryOffset + 1000
            this.fetchTableData(newQueryOffset);
        }
    }
    onFilterApplied(filter) {
        this.fetchTableData(0, filter);
    }
    afterImport() {
        this.fetchTableData(0);
    }
    
    render() {
        return !this.state.loaded ? (<div>Loading...</div>) : (
            <div>
                { this.state.name_edit_mode ?
                <div>
                    <input type="text" name="name" value={this.state.new_name} onChange={this.onNameChange.bind(this)} />
                    <button onClick={this.submitNameChange.bind(this)}>Save</button>
                    <button onClick={() => this.setState({name_edit_mode: false, new_name: this.state.table_config.name})}>Cancel</button>
                </div> :
                <div>
                    <h1>{this.state.table_config.name}</h1>
                    <button onClick={() => this.setState({name_edit_mode: true})}>Rename</button>
                </div>
                }
                <h2>Attributes:</h2>
                {
                    this.state.edit_mode ?
                <TableAttributes new_fields={this.state.edit_mode_attributes} edit_mode={true} onChange={this.onAttributeChange.bind(this)} /> :
                <ol>
                    {this.state.table_config.attributes.map(a => <li key={a.name}>{a.name} {a.type}</li>)}
                </ol>
                }
                {
                    this.state.edit_mode ?
                <div>
                    <button onClick={this.submitAttributeChange.bind(this)}>Modify Table</button>
                    <button onClick={this.cancelAttributeChange.bind(this)}>Cancel</button>
                </div> :
                <button onClick={this.onChangeEditMode.bind(this)}>Edit</button>
                }
                <h2>Add a record:</h2>
                <TableForm table_config={this.state.table_config} user={this.props.user} on_record_added={this.onRecordAdded.bind(this)} />
                <h2>Data:</h2>
                <DataTable
                    table_config={this.state.table_config}
                    data={this.state.records ? this.state.records.slice(this.state.offset, this.state.offset + 100) : null}
                    onDeleteRecord={this.deleteRecord.bind(this)}
                    onUpdateRecord={this.updateRecord.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    pagingDisabled={this.state.loading}
                    showPaging={this.state.table_config && this.state.table_config.is_paging_supported && 
                        this.state.records && this.state.records.length >= 100}
                    onFilterApplied={this.onFilterApplied.bind(this)}
                />
                <h3>Import a CSV file:</h3>
                <TableImport
                    access_token={this.props.access_token}
                    table_config={this.state.table_config}
                    afterImport={this.afterImport.bind(this)} />
            </div>
        )
    }
}


export function TableDataRoute () {
    const { user, access_token } = useContext(UserContext);
    const { table_name } = useParams();
    return (
        <TableData user={user} table_name={table_name} access_token={access_token} />
    )
}
