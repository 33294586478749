import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faTools, faDove } from '@fortawesome/free-solid-svg-icons';
import { LoginButton } from './AuthControls';

export function PublicHome() {
    if (process.env.NODE_ENV === 'production') {
        window.gtag('event', 'page_view', {
          page_path: window.location.pathname + window.location.search + window.location.hash
        })
      }
    return (
      <div className="public-home">
        <h1 className="public-header">Write <strong>less</strong> build more</h1>
        <LoginButton />
        <div className="tiles">
            <div className="image"><FontAwesomeIcon icon={faCogs} /></div>
            <div className="text">
                <h2>Build data-intensive software components in minutes, not days</h2>
                <ul>
                    <li>Data stores (RDBMS, Key-value)</li>
                    <li>APIs</li>
                    <li>Batch processes</li>
                    <li>Event-based workflows</li>
                    <li>3rd Party integrations</li>
                </ul>
            </div>
            <div className="image"><FontAwesomeIcon icon={faTools} /></div>
            <div className="text">
                <h2>Production grade from day one</h2>
                <ul>
                    <li>Information security, compliance and strong access control baked-in</li>
                    <li>Run on your own Cloud or hosted by us</li>
                    <li>Supports your existing software development process: source control, CI/CD, versioning</li>
                    <li>Build components for real-world, customer-facing, production workloads</li>
                </ul>
            </div>
            <div className="image"><FontAwesomeIcon icon={faDove} /></div>
            <div className="text">
                <h2>Full extensibility, zero lock-in</h2>
                <ul>
                    <li>Seamlessly switch between point-and-click and writing your own code</li>
                    <li>Export everything: code and data</li>
                    <li>Open Source Software under the hood</li>
                </ul>
            </div>
        </div>
        <h2>Demo: Create a database table and API in the cloud in 60 seconds</h2>
        <div id="public-demo"><img src="/demo.gif" alt="Animated gif demo"></img></div>
        <h2>Built with less</h2>
        <div id="built-with-less">
            <div>
                <h3><a href="https://www.triptesting.com" target="_blank" rel="noreferrer">Trip Testing</a> - find COVID-19 testing labs for travel</h3>
                <img src="/triptesting.com.png" alt="triptesting.com screenshot"></img>
            </div>
            <div>
                <h3><a href="https://supernotes.net" target="_blank" rel="noreferrer">Supernotes</a> - take rich interlinked notes</h3>
                <img src="/supernotes.net.png" alt="supernotes.net screenshot"></img>
            </div>
        </div>
      </div>
    )
}
  
  