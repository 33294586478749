import axios from 'axios';
import config from './config';

export const axiosInstance = (access_token) => {
    return axios.create({
        baseURL: config.api_root,
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });
}

export const messageFromResponse = (err, defaultMessage) => {
    return (typeof(err.response) === 'object' &&
    'data' in err.response && 'message' in err.response.data) ?
        err.response.data.message : defaultMessage;
}
