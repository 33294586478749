import React, { Component, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import { UserContext } from './AuthControls';
import { axiosInstance, messageFromResponse } from './utils';
import { ApiCustomCodeEditor, defaultCustomCode } from './Tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

function ApiMethod(props) {
    return (
        <div className="api-method">
            <div className="description">{props.description}</div>
            <div className="code"><pre><code>{props.code}</code></pre></div>
        </div>
    )
}
export function defaultJwtAuth() {
    return {
        enabled: false,
        jwks: '',
        audience: '',
        issuer: ''
    }; 
}
export const authFieldMap = {
    jwks: {
        display: 'JSON Web Key Set', 
        database_field: 'token_jwks'
    },
    audience: {
        display: 'Audience', 
        database_field: 'token_audience'
    },
    issuer: {
        display: 'Issuer',
        database_field: 'token_issuer'
    },
    enabled: {
        display: 'JWT Authentication',
        database_field: 'jwt_authentication'
    }
};
export function isAuthValid(jwt_auth) {
    if (jwt_auth.enabled) {
        for (let f in authFieldMap) {
            if (f !== 'enabled' && (!(f in jwt_auth) || jwt_auth[f].trim() === "")) {
                alert(`Please enter a value for ${authFieldMap[f].display}`);
                return false;
            }
        };
    }
    return true;
}

export class ApiAuthentication extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            jwt_properties: {
                enabled: false
            }
        }
        this.state.jwt_properties = this.makeJwtProps(props);
        this.onChange = this.onChange.bind(this);
    }
    makeJwtProps(props) {
        const jwt_properties = defaultJwtAuth();
        if (props.jwt) {
            ['enabled', 'jwks', 'audience', 'issuer'].forEach(f => {
                if (f in props.jwt) {
                    jwt_properties[f] = props.jwt[f];
                }
            });
        }
        return jwt_properties;
    }
    componentDidMount() {
        const newProps = this.makeJwtProps(this.props);
        this.setState({jwt_properties: newProps});
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.jwt && !prevProps.jwt) {
            this.setState({jwt_properties: this.makeJwtProps(this.props)})
        }
    }
    
    
    

    onChange(e) {
        const p = {...this.state.jwt_properties};
        p[e.target.name] = e.target.type === "text" ? e.target.value : e.target.checked;
        this.setState({jwt_properties: p});
        if (typeof this.props.onChange === "function") {
            this.props.onChange(p);
        }
    }
    render() {
        return <div>
            <input type="checkbox" name="enabled" checked={this.state.jwt_properties.enabled} onChange={this.onChange} />
            <label>JWT authentication</label>
            <ul style={{display: this.state.jwt_properties.enabled ? "block": "none"}}>
                <li><input placeholder="JSON Web Key Set" type="text" name="jwks" value={this.state.jwt_properties.jwks} onChange={this.onChange} /></li>
                <li><input placeholder="Audience" type="text" name="audience" value={this.state.jwt_properties.audience} onChange={this.onChange} /></li>
                <li><input placeholder="Issuer" type="text" name="issuer" value={this.state.jwt_properties.issuer} onChange={this.onChange} /></li>
            </ul>
        </div>
    }
}
class ApiComponent extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            include_custom_code: false,
            original_include_custom_code: false,
            custom_code: defaultCustomCode(),
            all_tables: [],
            jwt_auth: defaultJwtAuth()
        }
    }

    pollApi() {
        const maxPolls = 40;
        let currentPoll = 0;
        const interval = window.setInterval(() => {
            if (currentPoll >= maxPolls) {
                window.clearInterval(interval);
                return;
            }
            currentPoll++;
            axiosInstance(this.props.access_token).get(`/Api?name=${this.props.api_name}`)
            .then(res => {
                const new_state = this.makeApiState(res);
                if (res.data.status !== "Pending") {
                    window.clearInterval(interval);
                    new_state.interval = null;
                }
                this.setState(new_state);
            });    
        }, 5000);
        this.setState({
            pollingInterval: interval
        });
    }

    fetchApiAndTables() {
        if (!this.state.loading && this.props.user && this.props.user.org) {
            this.setState({loading: true})
            Promise.all([
                axiosInstance(this.props.access_token).get(`/Api?name=${this.props.api_name}`),
                axiosInstance(this.props.access_token).get('/Table')
            ])
            .then(res => {
                const [api, tables] = res;
                const new_state = this.makeApiState(api);
                new_state.all_tables = tables.data;
                this.setState(new_state);
                if (api.data.status === "Pending") {
                    this.pollApi();
                }
            });
        }
    }
    makeApiState(api) {
        const custom_code_exists = 'custom_code' in api.data && api.data['custom_code'].trim() !== '';
        const new_state = {
            api_config: api.data,
            include_custom_code: custom_code_exists,
            original_include_custom_code: custom_code_exists,
            jwt_auth: {}
        };
        if (custom_code_exists) {
            new_state.custom_code = api.data.custom_code;
        }
        for (let f in authFieldMap) {
            if (authFieldMap[f].database_field in api.data) {
                new_state.jwt_auth[f] = api.data[authFieldMap[f].database_field];
            } else {
                new_state.jwt_auth[f] = (f === 'enabled' ? false : '');
            }
        }
        return new_state;
    }

    componentDidUpdate(prevProps, prevState) {
        this.fetchApiAndTables();
    }
    
    componentDidMount() {
        this.fetchApiAndTables();
    }

    onEditorChange(val, e) {
        this.setState({custom_code: val});
    }

    onIncludeCustomCodeChange(e) {
        this.setState({include_custom_code: e.target.checked});
    }

    componentWillUnmount() {
        if (this.state.interval) {
            window.clearInterval(this.state.interval);
        }
    }


    updateCustomCode() {
        if (!this.state.include_custom_code) {
            if (this.state.original_include_custom_code && !window.confirm("Existing custom code will be deleted. Are you sure?")) {
                return;
            }
            if (!this.state.original_include_custom_code) {
                alert("No changes made");
                return;
            }
        }
        const body = {
            name: this.state.api_config.name,
            custom_code: this.state.include_custom_code ? this.state.custom_code : ''
        };
        axiosInstance(this.props.access_token).post('/Api', body)
        .then(res => {
            const api_config = {...this.state.api_config};
            api_config.status = res.data.status;
            this.setState({api_config});
        }).catch(err => alert(messageFromResponse(err, "Cannot update API")))
    }

    onFieldPropChange(e) {
        const api_config = {...this.state.api_config};
        let tables = [...api_config.tables];
        if (e.target.checked) {
            if (tables.every(t => t.name !== e.target.name)) {
                tables.push({name: e.target.name});
            }
        } else {
           tables = tables.filter(t => t.name !== e.target.name); 
        }
        api_config.tables = tables;
        this.setState({api_config});
    }

    submitTables() {
        if (this.state.api_config.tables.length === 0) {
            alert("Please select at least one table");
            return;
        }
        const body = {
            name: this.state.api_config.name,
            tables: this.state.api_config.tables
        };
        axiosInstance(this.props.access_token).post('/Api', body)
        .catch(err => alert(messageFromResponse(err, "Cannot update API")))
    }

    onJwtAuth(auth) {
        this.setState({jwt_auth: auth});
    }
    updateAuth() {
        if (!isAuthValid(this.state.jwt_auth)) { 
            return;
        }
        const body = {
            name: this.state.api_config.name
        };
        for (let f in authFieldMap) {
            body[authFieldMap[f].database_field] = this.state.jwt_auth[f];
        }
        axiosInstance(this.props.access_token).post('/Api', body)
        .catch(err => alert(messageFromResponse(err, "Cannot update API")))
    }
    render() {
        if (this.state.api_config) {
            if (this.state.api_config.status === "Pending") {
                return <div>
                    API is {this.state.api_config.status}
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                    <p>APIs take about 60 seconds to create</p>
                </div>
            } else if (this.state.api_config.status === "Broken") {
                return <div>Error while creating API</div>
            }
            const { api_config } = this.state;
            const root = api_config.endpoint;
            return (
            <div>
                <h1>API: {this.props.api_name}</h1>
                <h2>Included tables</h2>
                <ol>
                    {
                        this.state.all_tables.map((t, i) => <li key={i}>
                            <input
                                type="checkbox"
                                checked={this.state.api_config.tables.some(api_table => api_table.name === t.name)}
                                name={t.name}
                                onChange={this.onFieldPropChange.bind(this)}
                            />
                            <label>{t.name}</label>
                        </li>)
                    }
                </ol>
                <button onClick={this.submitTables.bind(this)}>Update tables</button>
                <h2>Authentication</h2>
                <ApiAuthentication jwt={this.state.jwt_auth} onChange={this.onJwtAuth.bind(this)} />
                <button onClick={this.updateAuth.bind(this)}>Update authentication</button>
                <h2>Custom code</h2>
                <ApiCustomCodeEditor
                    include_custom_code={this.state.include_custom_code}
                    onIncludeCustomCodeChange={this.onIncludeCustomCodeChange.bind(this)}
                    custom_code={this.state.custom_code}
                    onEditorChange={this.onEditorChange.bind(this)}
                />
                <button onClick={this.updateCustomCode.bind(this)}>Update custom code</button>
                <h2>API Documentation</h2>
                <ul>
                {api_config.tables.map((t, i) =>
                    {
const table_root = `-H "X-Api-Key: ${api_config.api_key_value}" \\
    ${root}${t.name}`;
                        return (<li key={i}>
                            <h2>{t.name}</h2>
<ApiMethod key="0" description="Create a new record" code={`curl -X POST \\
    -d '{"field": "value"}' \\
    ${table_root}`} />
<ApiMethod key="1" description="Update a record" code={`curl -X POST \\
    -d '{"field": "value"}' \\
    ${table_root}?id=ID_HERE`} />
<ApiMethod key="2" description="Get one record" code={`curl ${table_root}?id=ID_HERE`} />
<ApiMethod key="3" description="List records" code={`curl ${table_root}`} />
<ApiMethod key="4" description="Delete record" code={`curl -X DELETE \\
    ${table_root}?Id=id`} />
                        </li>);
                    }
                )}
                </ul>
            </div>
            );
        } else {
            return (<span>Loading...</span>);
        }
    }
}

export function ApiRoute () {
    const { user, access_token } = useContext(UserContext);
    const { api_name } = useParams();
    return (
        <>
        <div><Link to={`/api/${api_name}/logs`}>Logs</Link></div>
        <ApiComponent api_name={api_name} user={user} access_token={access_token} />
        </>
    )
}

export function ApiLogsRoute () {
    const { access_token } = useContext(UserContext);
    const { api_name } = useParams();
    const [events, setEvents] = useState(null);
    if (access_token) {
        axiosInstance(access_token).get(`/ApiLogEvents?name=${api_name}`).then(res => setEvents(res.data));
    }
    if (events === null) {
        return <div>Loading...</div>;
    }
    return <>
        <Link to={`/api/${api_name}`}>API configuration</Link>
        <h1>API Logs {api_name}</h1>
        <table>
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
                {events.map((e, i) => <tr key={i}>
                    <td>{new Date(e.timestamp).toString()}</td>
                    <td>{e.message}</td>
                </tr>)}
            </tbody>
        </table>
    </>;
}