const local = {
    api_root : "http://127.0.0.1:3000/"

};
const dev = {
    api_root: "https://t1qjbldr3g.execute-api.us-west-2.amazonaws.com/Prod/"
    
};
const prod = {
    api_root: "https://pzl4olv9y9.execute-api.us-west-2.amazonaws.com/Prod/"
}
export default process.env.NODE_ENV === 'production' ? prod : dev;